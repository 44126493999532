import type { MetricWithAttribution } from 'web-vitals/attribution';
import { getGtmEventQueue } from './gtmEventQueue';
import { eventTypeConstants } from './constants';

// see https://github.com/GoogleChrome/web-vitals?tab=readme-ov-file#send-attribution-data
function getDebugTarget(metric: MetricWithAttribution): string | undefined {
    if (metric.attribution) {
        switch (metric.name) {
            case 'CLS':
                return metric.attribution.largestShiftTarget;
            case 'INP':
                return metric.attribution.interactionTarget;
            case 'LCP':
                return metric.attribution.element;
        }
    }
    return void 0;
}

// MetricWithAttribution type guard
export const isWebVitalMetric = (metric: unknown): metric is MetricWithAttribution =>
    typeof (metric as MetricWithAttribution)?.name === 'string' &&
    typeof (metric as MetricWithAttribution)?.value === 'number' &&
    typeof (metric as MetricWithAttribution)?.delta === 'number' &&
    typeof (metric as MetricWithAttribution)?.rating === 'string' &&
    typeof (metric as MetricWithAttribution)?.id === 'string' &&
    typeof (metric as MetricWithAttribution)?.navigationType === 'string' &&
    typeof (metric as MetricWithAttribution)?.attribution !== 'undefined';

export function trackWebVitalsEvent(metric: MetricWithAttribution): void {
    if (!isWebVitalMetric(metric)) {
        return;
    }
    // Remove entries, attribution properties, otherwise GTM will return null values,
    // because the type of entries is not supported.
    // from https://github.com/google-marketing-solutions/web-vitals-gtm-template/blob/main/template.tpl
    // Adds debugTarget property to the metric object.
    const webVital = {
        name: metric.name,
        value: metric.value,
        rating: metric.rating,
        delta: metric.delta,
        id: metric.id,
        navigationType: metric.navigationType,
        debugTarget: getDebugTarget(metric),
    };
    getGtmEventQueue().push({
        category: 'Web Vitals',
        event: eventTypeConstants.EVENT_PERF_WEB_VITALS,
        eventName: 'web_vitals',
        webVital,
    });
}
