/**
 * @generated SignedSource<<18eeeea333a7f195ff1fe4fe0ed139bf>>
 * @relayHash dc8730e794866555c056783b8aa64ef2
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/492.0.0-2024-12-12T19:54:28.594Z/userGeoInfoQuery

import { ConcreteRequest, Query } from 'relay-runtime';
export type userGeoInfoQuery$variables = {
  cookieDomain?: string | null;
  userId?: string | null;
};
export type userGeoInfoQuery$data = {
  readonly viewer: {
    readonly regionalInfo: ReadonlyArray<{
      readonly countryCode: string | null;
      readonly incomeLevel: {
        readonly incomeBracket: string | null;
        readonly incomePercentile: string | null;
      } | null;
      readonly regionsByZipCode: ReadonlyArray<{
        readonly displayName: string | null;
        readonly urlLabel: string | null;
      } | null> | null;
      readonly zipCode: string | null;
    } | null> | null;
  };
};
export type userGeoInfoQuery = {
  response: userGeoInfoQuery$data;
  variables: userGeoInfoQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "cookieDomain"
},
v1 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "userId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "cookieDomain",
    "variableName": "cookieDomain"
  },
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countryCode",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "zipCode",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "RegionsByZipCode",
  "kind": "LinkedField",
  "name": "regionsByZipCode",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "urlLabel",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "IncomeLevel",
  "kind": "LinkedField",
  "name": "incomeLevel",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "incomeBracket",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "incomePercentile",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "userGeoInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "RegionalInfo",
            "kind": "LinkedField",
            "name": "regionalInfo",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "userGeoInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "RegionalInfo",
            "kind": "LinkedField",
            "name": "regionalInfo",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "ferrum/492.0.0-2024-12-12T19:54:28.594Z/userGeoInfoQuery",
    "metadata": {},
    "name": "userGeoInfoQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "3226ea68342751da356bdbfd98f225c0";

export default node;
